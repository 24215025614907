/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as wpApi from '../wordpress-api/wp-api';
import { News } from '../models/News';
import { Author } from '../models/Author';
import moment from 'moment';
import { checkEventIntersection } from './Time.service';

/**
 * Return post label based on the postType.
 * @param postType Post type: {post, dd_official_posts}
 * @returns Post label.
 */
const getPostTypeLabel = (postType: string) => {
	switch (postType) {
		case 'post':
			return 'Aktuell';
		case 'dd_official_posts':
			return 'Amtliche Meldung';
		default:
			return 'Veranstaltung';
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function processNewsWPResponse(post: any): News {
	const id = post.id;
	const title = post?.title?.rendered || '';
	const publishDate = moment(post.date).valueOf();
	const tag = getPostTypeLabel(post.type);
	const justInsideP = post.excerpt?.rendered?.replace(/<\/?[^>]+(>|$)/g, ''); // TODO: How to deal if we have images in the excerpt?
	const excerpt = justInsideP;
	const qrUrl = post.link;
	const backgroundImageUrl = post.imageURL ?? '';
	const endDate = post.endTimeLong ?? undefined;
	const startDate = post.startTimeLong ?? undefined;
	const author: Author = { id: post.author, name: post.authorName };
	const location = post.locationName ?? undefined;

	const news: News = {
		id,
		title,
		tag,
		publishDate,
		author,
		excerpt,
		qrUrl,
		backgroundImageUrl,
		startDate,
		endDate,
		location,
	};
	return news;
}

function getNextWeekEvents(events: any) {
	const EVENTS_RANGE = 14; // show events that take place within the next 14 days
	return (
		events.filter((eventObject: any) => {
			const startMomentDate = moment().startOf('day').format(); // today 00:00
			const endMomentDate = moment(startMomentDate).add(EVENTS_RANGE, 'days').endOf('day').format();
			const eventStartDate = moment(eventObject?.startTimeLong).format();
			const eventEndDate = moment(eventObject?.endTimeLong).format();
			if (
				eventObject?.startTimeLong &&
				checkEventIntersection(eventStartDate, eventEndDate, startMomentDate, endMomentDate)
			) {
				return eventObject;
			} else {
				return false;
			}
		}) ?? []
	);
}

/**
 * Return posts/events with active dd_to_publish_as_showcase property.
 * @param news posts or events to be filtered.
 * @returns Return those posts/events with dd_to_publish_as_showcase equals to "1" (if the checkbox is selected in Wordpress)
 */
function getPublishedAsShowCase(news: any) {
	return news.filter((posts: any) => posts.dd_to_publish_as_showcase && posts.dd_to_publish_as_showcase === '1') ?? [];
}

// Get news from WordPress API
export async function getNews(isDorfNewsInstance: boolean): Promise<News[]> {
	const LAST_NUMBER_OF_POSTS_TO_SHOW = 5;
	const posts = await wpApi.getAllPosts();
	/* If the page is a dorfpages instance, don't poll getAllOfficialPosts as this plugin is not existing on these instances */
	const officialPosts = isDorfNewsInstance ? await wpApi.getAllOfficialPosts() : [];
	const events = await wpApi.getAllEvents();
	const filteredEvents = getNextWeekEvents(events);
	const filteredPublishedEvents = getPublishedAsShowCase(filteredEvents);
	const filteredPublishedPosts = getPublishedAsShowCase(posts).slice(0, LAST_NUMBER_OF_POSTS_TO_SHOW);
	const filteredPublishedAllPosts = getPublishedAsShowCase(officialPosts).slice(0, LAST_NUMBER_OF_POSTS_TO_SHOW);
	// merge post, official posts and events
	const newsList = filteredPublishedPosts.concat(filteredPublishedEvents, filteredPublishedAllPosts);
	const processedNews: News[] = newsList.map((news: any) => processNewsWPResponse(news));
	const sortedNews: News[] = processedNews.sort((a, b) => (b.publishDate ?? 0) - (a.publishDate ?? 0));
	return Promise.resolve(sortedNews);
}

export async function getBreakingNews(isDorfNewsInstance: boolean): Promise<News[]> {
	let breakingNews;
	if (isDorfNewsInstance) {
		breakingNews = await wpApi.ddNews();
	} else {
		breakingNews = await wpApi.getAllBreakingNews();
	}
	const populatedBreakingNews: News[] = breakingNews.map((bNews: any) => processNewsWPResponse(bNews));
	return Promise.resolve(populatedBreakingNews);
}

/**
 * Compare the two news list and manages next things:
 * Assumption: All news are sorted by publish date.
 * 1. Updates all common old news with the new news list and add to an specific updatedNewsList.
 * 2. Add all new news to the the updatedNewsList.
 * 3. Removes all news that are not found in the old news list.
 * 4. Always respects oldNews sort in the updatedNewsList.
 * @param oldNews List of old news.
 * @param newNews List of the updated news.
 */
export const getUpdatedNews = (oldNews: News[], newNews: News[]): News[] => {
	let updatedNewsList: News[] = [];
	// Filter just new news
	const onlyRealNewNews: News[] = newNews.filter((nNews: News) => {
		return oldNews.filter((oNews: News) => nNews.id === oNews.id).length === 0;
	});

	// 	Case 1: Updates all old news list objects
	oldNews.filter((oNews: News) => {
		return (
			newNews.filter((nNews: News) => {
				if (nNews.id === oNews.id) {
					// lets update the old Element
					updatedNewsList.push(nNews);
				}
				return nNews.id === oNews.id;
			}).length === 0
		);
	});
	let indexNewestNewsInOldNewsList;

	// Search index of the newest news in the old news list
	newNews.some((nNews) => {
		const index = oldNews.findIndex((oNews) => nNews.id === oNews.id);
		if (index !== -1) {
			indexNewestNewsInOldNewsList = index;
			return true;
		} else {
			return false;
		}
	});
	// Case 2: Add all new news
	if (onlyRealNewNews.length > 0 && indexNewestNewsInOldNewsList !== undefined) {
		if (indexNewestNewsInOldNewsList === 0) {
			updatedNewsList = onlyRealNewNews.concat(updatedNewsList);
		} else {
			updatedNewsList.splice(indexNewestNewsInOldNewsList, 0, ...onlyRealNewNews);
		}
	}

	// Case 3: When newNewsList is replacing the complete oldList
	if (indexNewestNewsInOldNewsList === undefined) {
		updatedNewsList = newNews;
	}
	return updatedNewsList;
};
