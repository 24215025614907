import { Author } from './Author';

export interface News {
	id?: string;
	type?: NewsType;
	tag?: string;
	publishDate?: number;
	title?: string;
	author?: Author;
	excerpt?: string;
	qrUrl?: string;
	startDate?: number;
	endDate?: number;
	location?: string;
	backgroundImageUrl?: string;
}

export enum NewsType {
	POST = 'POST',
	EVENT = 'EVENT',
}

export interface NewsItem {
	id?: string;
	title?: string;
}

export enum WEB_SITE_TYPE {
	DORFNEWS = 'DORFNEWS',
	DORFPAGES = 'DORFPAGES',
}
