import React from 'react';
import QRCode from 'qrcode.react';

interface Props {
	portrait?: boolean;
	url: string;
}

const NewsContentQRCode = (props: Props) => {
	const { portrait, url } = props;

	return (
		<div className={portrait ? 'content-qr-wrap-portrait' : 'content-qr-wrap-landscape'}>
			{url ? (
				<div className="content-qr">
					<span>Weiterlesen: </span>
					<QRCode value={url} />
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default NewsContentQRCode;
