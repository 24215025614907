import React from 'react';
import { News } from '../models/News';
import { truncate, unescapeHTML } from '../services/Util.service';
import NewsContentTag from './NewsContentTag.view';
import NewsContentProgressBar from './NewsContentProgressBar.view';
import NewsContentQRCode from './NewsContentQRCode.view';
import { isSameDay, parseTimestamp, TimestampFormat } from '../services/Time.service';
import moment from 'moment';

export function getEventTime(startTime: number, endTime: number) {
	const start = new Date(startTime);
	const end = new Date(endTime);
	const startDateFormatted = parseTimestamp(startTime || 0, TimestampFormat.DD_MM_YY_HH_MM);
	const endDateFormatted = parseTimestamp(endTime || 0, TimestampFormat.DD_MM_YY_HH_MM);

	if (isSameDay(start, end)) {
		return `${startDateFormatted}-${parseTimestamp(endTime || 0, TimestampFormat.HH_MM)}`;
	} else {
		return `${startDateFormatted}-${endDateFormatted}`;
	}
}

interface Props {
	currentNews: News;
}

const NewsContentBox = (props: Props) => {
	const MAX_TITLE_CHARACTERS = 75;
	const MAX_TEXT_CHARACTERS = 250;
	const { currentNews } = props;
	const title = unescapeHTML(currentNews?.title ?? '') || '';
	const text = unescapeHTML(currentNews?.excerpt ?? '') || '';
	const eventDays =
		currentNews?.startDate && currentNews.endDate ? getEventTime(currentNews.startDate, currentNews.endDate) : '';
	const publishedDate = parseTimestamp(currentNews?.publishDate ?? moment().valueOf(), TimestampFormat.DD_MM_YY);
	const location = currentNews.location && currentNews.location !== 'Unbekannt' ? ` - ${currentNews.location}` : '';
	return (
		<div className="content-box-wrap">
			<section className="content-box">
				<NewsContentProgressBar portrait={true} currentNews={currentNews} />
				<div className="content-box-inner">
					<NewsContentTag tagName={currentNews?.tag ?? ''} portrait={false} />
					<p className="content-box-inner__meta">{currentNews.startDate ? eventDays + location : publishedDate}</p>
					<h1>{truncate(title, MAX_TITLE_CHARACTERS)}</h1>
					<p className="content-box-inner__author">von {currentNews?.author?.name}</p>
					<p className="content-box-inner__copy">{truncate(text, MAX_TEXT_CHARACTERS)}</p>
					<NewsContentQRCode url={currentNews?.qrUrl ?? ''} />
				</div>
				<NewsContentProgressBar portrait={false} currentNews={currentNews} />
			</section>
		</div>
	);
};

export default NewsContentBox;
