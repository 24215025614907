import React from 'react';
import { unescapeHTML } from '../services/Util.service';

interface Props {
	tickerNews: string[];
}

const NewsTicker = (props: Props) => {
	const { tickerNews } = props;
	if (tickerNews.length === 0) {
		return <></>;
	}
	return (
		<div className="news-ticker-wrap">
			<section className="news-ticker">
				{tickerNews.map((tickerNews, i) => (
					<React.Fragment key={i}>
						<div className="news-ticker__item">{unescapeHTML(tickerNews)}</div>
						<span>&#183;</span>
					</React.Fragment>
				))}
			</section>
		</div>
	);
};

export default NewsTicker;
