import { useEffect, useRef } from 'react';
import { WEB_SITE_TYPE } from '../models/News';

export function unescapeHTML(html: string) {
	const escapeEl = document.createElement('textarea');
	escapeEl.innerHTML = html;
	return escapeEl.textContent;
}

// Hook
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePrevious(value: any) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef();

	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes

	// Return previous value (happens before update in useEffect )
	return ref.current;
}

export function truncate(source: string, size: number) {
	return source.length > size ? source.slice(0, size - 1) + '…' : source;
}

/**
 * Returns the correct event url based on the given website type
 * @param {WEB_SITE_TYPE} webSiteType {DORFNEWS, DORFPAGES}
 * @returns tribe_events or lsvr_event
 */
export const getEventsRouteFromSiteType = (webSiteType: WEB_SITE_TYPE) => {
	switch (webSiteType) {
		case WEB_SITE_TYPE.DORFNEWS:
			return 'tribe_events';
		case WEB_SITE_TYPE.DORFPAGES:
			return 'lsvr_event';
		default:
			return 'lsvr_event';
	}
};

/**
 * Process the site type given in the url.
 * @param urlParams Search Params with the site type parameter.
 * @returns Web site type if exits, otherwise, undefined.
 */
export const getSiteTypeFromURL = (urlParams: URLSearchParams) => {
	const webSiteType = urlParams.get('type');
	switch (webSiteType?.toUpperCase()) {
		case WEB_SITE_TYPE.DORFNEWS:
			return WEB_SITE_TYPE.DORFNEWS;
		case WEB_SITE_TYPE.DORFPAGES:
			return WEB_SITE_TYPE.DORFPAGES;
		default:
			return WEB_SITE_TYPE.DORFPAGES;
	}
};
