import moment from 'moment';
import 'moment/locale/de';
import { isDorfPages } from '../../src/NewsContainer';

export enum TimestampFormat {
	DD_MM_YY_AT_HH_MM_O_CLOCK = 'DD.MM.YYYY [um] HH:mm [Uhr]',
	DD_MM_YY_HH_MM = 'DD.MM.YYYY HH:mm',
	DD_MM_YY = 'DD.MM.YYYY',
	DD_MMMM = 'DD. MMMM',
	DD = 'DD.',
	HH_MM = 'HH:mm',
}

export function parseTimestamp(timestamp: number, format: TimestampFormat) {
	if (isDorfPages) {
		const convertedTimestamp = moment(timestamp).locale('de').format(format);
		return convertedTimestamp;
	} else {
		const unchangedTimestamp = moment.utc(timestamp).format(format);
		return unchangedTimestamp;
	}
}

/**
 * Check if there is an intersection between the start end end dates and a given interval
 * @param stardDate StartDate to compare.
 * @param endDate EndDate to compare.
 * @param startInterval The start date of the range.
 * @param endInterval The end date of the range.
 */
export function checkEventIntersection(startDate: string, endDate: string, startInterval: string, endInterval: string) {
	// Convert the time strings to Date objects for comparison
	const eventStartTime = new Date(startDate);
	const eventEndTime = new Date(endDate);
	const intervalStartTime = new Date(startInterval);
	const intervalEndTime = new Date(endInterval);

	// Check if there is any intersection
	return eventStartTime < intervalEndTime && eventEndTime > intervalStartTime;
}

/**
 * Compares two dates to check if they are in the same day
 *
 * @param d1
 * @param d2
 * @returns true if both dates represent the same day
 */
export function isSameDay(d1: Date, d2: Date) {
	return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}
