import React from 'react';
import { unescapeHTML } from '../services/Util.service';
import { News, NewsItem } from '../models/News';
import NewsTicker from './NewsTicker.view';
import NewsContentQRCode from './NewsContentQRCode.view';

interface Props {
	currentNews: News;
	newsList: NewsItem[];
	breakingNewsList: News[];
}

const truncateString = (str: string | null, maxLength: number) => {
	if (!str) return '';
	if (str.length <= maxLength) return str;
	return str.substring(0, maxLength) + '...';
};

const NewsList = (props: Props) => {
	const { newsList, currentNews, breakingNewsList } = props;
	const tickerNews: string[] = breakingNewsList.map((breakingNews) => breakingNews.title ?? '');
	return (
		<div className="toc-news-ticker-wrap">
			{newsList.length === 0 ? (
				<></>
			) : (
				<div className="content-toc-wrap">
					<NewsContentQRCode portrait={true} url={currentNews?.qrUrl ?? ''} />
					<section className="toc">
						<p className="toc__header">Nächste Meldungen:</p>
						<ul>
							{newsList.map((news, i) => (
								<li key={i} className="toc__item">
									{truncateString(unescapeHTML(news.title ?? ''), 120)}
								</li>
							))}
						</ul>
					</section>
				</div>
			)}

			<NewsTicker tickerNews={tickerNews} />
		</div>
	);
};

export default NewsList;
