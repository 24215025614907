import NewsList from './views/NewsList.view';
import { News, NewsItem, WEB_SITE_TYPE } from './models/News';
import React, { useEffect, useState } from 'react';
import { getSiteTypeFromURL, usePrevious } from './services/Util.service';
import NewsContentTag from './views/NewsContentTag.view';
import NewsContentBox from './views/NewsContentBox.view';
import LoadingIndicator from './views/LoadingIndicator.view';
import { getBreakingNews, getNews, getUpdatedNews } from './services/News.service';
import defaultBackground from './assets/assets_featured-image-placeholder.jpg';

const NewsContainer = () => {
	const numberOfElementsToShow = 6;

	const NEXT_CURRENT_NEWS_TIMING = 20000; // in milliseconds
	const POLLING_UPDATED_NEWS_TIMING = 180000; // in milliseconds

	const [loading, setLoading] = useState<boolean>(true);

	const [currentNewsTimer, setCurrentNewsTimer] = useState<number>(0);
	const [currentNews, setCurrentNews] = useState<News>({});

	const [completeNewsListUpdated, setNewCompleteNewsList] = useState<boolean>(false);
	const [completeNewsList, setCompleteNewsList] = useState<News[]>([]);

	const [availableNews, setAvailableNews] = useState<News[]>([]);
	const [tableNewsList, setTableNewsList] = useState<NewsItem[]>([]);

	const [breakingNewsList, setBreakingNewsList] = useState<News[]>([]);

	// Get the previous value (was passed into hook on last render)
	const prevCurrentNewsTimer = usePrevious(currentNewsTimer);

	/**
	 * Change current element background to the given by parameter.
	 * @param element The html element that will be have given background.
	 * @param backgroundUrl The URL of the new background.
	 */
	const changeBackgroundImage = (element: HTMLElement, backgroundUrl: string) => {
		element.style.backgroundImage = 'url(' + backgroundUrl + ')';
		element.style.backgroundRepeat = 'no-repeat';
		element.style.backgroundColor = 'white';
	};

	const removeProgressBar = () => {
		const htmlTag = document.getElementsByTagName('html')[0];
		htmlTag.classList.add('single-post');
	};

	const updateTableNewsList = (nextTableList: News[]) => {
		const nextNewsItemList = nextTableList.map((rn) => {
			return { id: rn.id, title: rn.title ? rn.title : '' };
		});
		setTableNewsList(nextNewsItemList);
	};

	const updateAllAvailableBackgroundImages = (allAvailableNews: News[]) => {
		allAvailableNews.forEach((news) => {
			const img: HTMLImageElement = document.createElement('img');
			img.src = news.backgroundImageUrl ?? ''; // Assigning the img src immediately requests the image
		});
	};

	/**
	 * Updates next states values:
	 * 1. currentNews
	 * 2. availableNews
	 * 3. tableNewsList
	 */
	const updateCurrentNewsStates = (news: News[]) => {
		const availableNewsCopy = [...news];
		const nextCurrentNews = availableNewsCopy[0];
		const firstNews = availableNewsCopy.shift();
		const nextTableList = news.slice(1, numberOfElementsToShow);
		if (firstNews) {
			availableNewsCopy.push(firstNews);
			setAvailableNews(availableNewsCopy);
		}
		setCurrentNews(nextCurrentNews);
		updateTableNewsList(nextTableList);

		if (nextTableList.length === 0 && nextCurrentNews?.id) {
			removeProgressBar();
		}
	};

	useEffect(() => {
		if (prevCurrentNewsTimer !== currentNewsTimer) {
			updateCurrentNewsStates(availableNews);
		}
		// eslint-disable-next-line
	}, [currentNewsTimer, availableNews, prevCurrentNewsTimer]);

	/**
	 * Updates the backgroundImage after the top-wrap element is loaded
	 */
	useEffect(() => {
		const updateCurrentBackground = (nextCurrentNews: News) => {
			const newBackgroundImage = nextCurrentNews?.backgroundImageUrl
				? nextCurrentNews.backgroundImageUrl
				: defaultBackground; // else dorfpage image?
			const topWrapElement = document.getElementById('top-wrap');
			changeBackgroundImage(topWrapElement ?? document.documentElement, newBackgroundImage);
			changeBackgroundImage(document.documentElement, newBackgroundImage);

			updateAllAvailableBackgroundImages(availableNews);
		};

		if (!loading && currentNews) {
			updateCurrentBackground(currentNews);
		}
	}, [loading, currentNews, availableNews]);

	/**
	 * Updates availableNews list in the background with new news list.
	 */
	useEffect(() => {
		if (completeNewsListUpdated) {
			const updatedNewsList = getUpdatedNews(availableNews, completeNewsList);
			updateAllAvailableBackgroundImages(updatedNewsList);
			setAvailableNews(updatedNewsList);
			setNewCompleteNewsList(false);
		}
	}, [completeNewsListUpdated, availableNews, completeNewsList]);

	/**
	 *  Interval to updates availableNews every POLLING_UPDATED_NEWS_TIMING
	 */
	useEffect(() => {
		/**
		 * Fetch all already processed News.
		 * @param initLoading True when the page is initializing the first time, otherwise, false.
		 */
		const getAllNews = async (initLoading: boolean) => {
			try {
				const urlParams = new URLSearchParams(window.location.search);
				const siteType: WEB_SITE_TYPE | undefined = getSiteTypeFromURL(urlParams);
				const isDorfnewsInstance = siteType === WEB_SITE_TYPE.DORFNEWS;
				const response: News[] = await getNews(isDorfnewsInstance);
				if (initLoading) {
					updateCurrentNewsStates(response);
					updateAllAvailableBackgroundImages(response);
					setLoading(false);
				} else {
					setCompleteNewsList(response);
					setNewCompleteNewsList(true);
				}
			} catch (error) {
				// handle error
			}
		};

		const getAllBreakingNews = async () => {
			const urlParams = new URLSearchParams(window.location.search);
			const siteType: WEB_SITE_TYPE | undefined = getSiteTypeFromURL(urlParams);
			const isDorfnewsInstance = siteType === WEB_SITE_TYPE.DORFNEWS;
			try {
				const breakingNews = await getBreakingNews(isDorfnewsInstance);
				setBreakingNewsList(breakingNews);
			} catch (error) {
				// handle error
			}
		};

		// Get all news initial load (just one time)
		getAllNews(true);
		getAllBreakingNews();
		const timer = setInterval(() => {
			getAllNews(false);
			getAllBreakingNews();
		}, POLLING_UPDATED_NEWS_TIMING);
		return () => clearInterval(timer);
		// eslint-disable-next-line
	}, []);

	/**
	 *  Interval to switch currentNews every NEXT_CURRENT_NEWS_TIMING
	 */
	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentNewsTimer((prevTimer) => prevTimer + 1);
		}, NEXT_CURRENT_NEWS_TIMING);
		return () => clearInterval(interval);
	}, []);

	return (
		<>
			{loading ? (
				<LoadingIndicator />
			) : !currentNews?.id ? (
				<></>
			) : (
				<>
					<div id="top-wrap" className="top-wrap-portrait">
						<NewsContentTag tagName={currentNews?.tag ?? ''} portrait={true} />
					</div>
					<div className="bottom-wrap">
						<NewsContentBox currentNews={currentNews} />
						<NewsList currentNews={currentNews} newsList={tableNewsList} breakingNewsList={breakingNewsList} />
					</div>
				</>
			)}
		</>
	);
};

const urlParams = new URLSearchParams(window.location.search);
const siteType: WEB_SITE_TYPE | undefined = getSiteTypeFromURL(urlParams);
export const isDorfPages = siteType === WEB_SITE_TYPE.DORFPAGES;

export default NewsContainer;
