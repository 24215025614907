import WPAPI from 'wpapi';
import moment from 'moment';
import { wpSettings } from '../services/Settings';
import { getEventsRouteFromSiteType } from '../services/Util.service';

const { url, username, password, webSiteType } = wpSettings;
const wp = new WPAPI({ endpoint: url, username, password });

const eventsURL = getEventsRouteFromSiteType(webSiteType);
wp.events = wp.registerRoute('wp/v2', `/${eventsURL}/(?P<id>)`, {
	// Listing any of these parameters will assign the built-in
	// chaining method that handles the parameter:
	params: ['before', 'after', 'location'],
});

wp.breakingNews = wp.registerRoute('wp/v2', '/lsvr_notice/(?P<id>)', {
	// Listing any of these parameters will assign the built-in
	// chaining method that handles the parameter:
	params: ['before', 'after', 'location'],
});

wp.ddNews = wp.registerRoute('wp/v2', '/dd_news/(?P<id>)', {
	// Listing any of these parameters will assign the built-in
	// chaining method that handles the parameter:
	params: ['before', 'after', 'location'],
});

wp.posts = wp.registerRoute('wp/v2', '/posts/(?P<id>)', {
	// Listing any of these parameters will assign the built-in
	// chaining method that handles the parameter:
	params: ['before', 'after', 'location'],
});

wp.officialPosts = wp.registerRoute('wp/v2', '/dd_official_posts/(?P<id>)', {
	// Listing any of these parameters will assign the built-in
	// chaining method that handles the parameter:
	params: ['before', 'after', 'location'],
});

const monthsAgoCurrentDate = moment().subtract(1, 'month').format();
const numberOfGeneralPosts = 30; // General means those with dd_to_publish_as_showcase or not activated

export const getAllPosts = async () => {
	try {
		const result = await wp
			.posts()
			.perPage(numberOfGeneralPosts)
			.order('desc')
			.param('after', monthsAgoCurrentDate) // all post from specified months ago
			.param('location', wpSettings.taxonomy)
			.get();
		return Promise.all(result);
	} catch (err) {
		// Implement error handler
		console.log(err);
		return [];
	}
};

export const getAllOfficialPosts = async () => {
	try {
		const result = await wp
			.officialPosts()
			.perPage(numberOfGeneralPosts)
			.order('desc')
			.param('after', monthsAgoCurrentDate) // all post from specified months ago
			.param('location', wpSettings.taxonomy)
			.get();
		return Promise.all(result);
	} catch (err) {
		// Implement error handler
		console.log(err);
		return [];
	}
};

export const getAllEvents = async () => {
	try {
		const result = await wp
			.events()
			.perPage(100) // testing purpose
			.order('asc')
			.param('after', monthsAgoCurrentDate) // all post from specified months ago
			.param('location', wpSettings.taxonomy)
			.get();
		return Promise.all(result);
	} catch (err) {
		// Implement error handler
		console.log(err);
		return Promise.all([]);
	}
};

export const getAllBreakingNews = async () => {
	try {
		return wp
			.breakingNews()
			.perPage(5) // testing purpose
			.order('asc')
			.param('location', wpSettings.taxonomy)
			.get();
	} catch (err) {
		// Implement error handler
		console.log(err);
		return Promise.all([]);
	}
};

export const ddNews = async () => {
	try {
		return wp
			.ddNews()
			.perPage(5) // testing purpose
			.order('asc')
			.param('location', wpSettings.taxonomy)
			.get();
	} catch (err) {
		// Implement error handler
		console.log(err);
		return Promise.all([]);
	}
};

export const getAllMedia = async () => {
	try {
		return wp.media().get();
	} catch (err) {
		// Implement error handler
		console.log(err);
	}
};

export const getAllAuthors = async () => {
	try {
		return wp
			.users()
			.perPage(100) // this may change in the future if we have more than 100 users...
			.get();
	} catch (err) {
		// Implement error handler
		console.log(err);
	}
};

export const getAuthor = async (authorId) => {
	try {
		return wp.users().id(authorId).get();
	} catch (err) {
		// Implement error handler
		console.log(err);
	}
};

export const getMedia = async (mediaId) => {
	try {
		return wp.media().id(mediaId).get();
	} catch (err) {
		// Implement error handler
		console.log(err);
	}
};
