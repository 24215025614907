import React, { useEffect, useState } from 'react';
import { News } from '../models/News';

interface Props {
	portrait: boolean;
	currentNews: News;
}

const NewsContentProgressBar = (props: Props) => {
	const { portrait, currentNews } = props;
	const [className, setClassName] = useState<string>('');
	// Workaround: Needed to reset the progressBar animation
	useEffect(() => {
		setClassName('');
		setTimeout(() => setClassName('content-box__progress'), 100);
	}, [currentNews]);

	return (
		<div className={portrait ? 'content-box-meter-wrap-portrait' : 'content-box-meter-wrap-landscape'}>
			<div className="content-box__meter">
				<span id="progressBar" className={className}></span>{' '}
			</div>
		</div>
	);
};

export default NewsContentProgressBar;
