import { WEB_SITE_TYPE } from '../models/News';
import { getSiteTypeFromURL } from './Util.service';

type WPSettings = {
	url: string;
	username?: string;
	password?: string;
	taxonomy?: string[];
	webSiteType?: WEB_SITE_TYPE;
};

// window.location.pathname always starts with '/', removing this
const urlParams = new URLSearchParams(window.location.search);
const url = urlParams.get('sourceurl') ?? 'https://www.gleichen.digital'; //TODO: TEMPORAL WORKAROUND.
const webSiteType = getSiteTypeFromURL(urlParams);

const completeUrl = url + '/wp-json';
const taxonomy = urlParams.getAll('geoarea');
export const wpSettings: WPSettings = { url: completeUrl, taxonomy, webSiteType };
