import React from 'react';

interface Props {
	tagName?: string;
	portrait?: boolean;
}

const NewsContentTag = (props: Props) => {
	const { tagName, portrait } = props;
	return (
		<div className={portrait ? 'content-tag-wrap-portrait' : 'content-tag-wrap-landscape'}>
			<span className="content-tag">{tagName}</span>
		</div>
	);
};

export default NewsContentTag;
